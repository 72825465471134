<template>
    <div @click.prevent.stop="">
        <label class="control-label" v-bind:data-form-id="formField.id" v-if="formField.type === TypeFormField.LABEL"  style="margin-top: 10px">{{formField.name}}</label>
        <div v-else @click.prevent.stop="" >
            <label v-if="formField.type_response !== TypeResponseFormField.CHOICE" class="control-label" v-bind:data-form-id="formField.id" >{{formField.name}}</label>

            <div v-if="formField.type_response === TypeResponseFormField.STRING" >
                <input  class="form-control" type="text" v-model="formValue.string_response"/>

            </div>

            <div v-else-if="formField.type_response === TypeResponseFormField.NUMERIC" class="form-group" :class="{'has-error': error.numeric}">
                <div :class="{'input-group': formField.unit_of_measure}" >
                    <input :placeholder="(formField.min_numeric_value && formField.max_numeric_value) && ('min: '+ formField.min_numeric_value + ' - max: ' + formField.max_numeric_value ) || formField.min_numeric_value && ('min: '+ formField.min_numeric_value) ||  formField.max_numeric_value && ('max: '+ formField.max_numeric_value)" class="form-control" type="number" step="0.01" :min="formField.min_numeric_value" :max="formField.max_numeric_value"  v-model.number="formValue.numeric_response"/>
                    <span v-if="formField.unit_of_measure" class="input-group-addon">{{formField.unit_of_measure}}</span>
                </div>
                <span class="help-block" v-if="error.numeric">Il numero inserito non è valido</span>
            </div>


            <div v-else-if="formField.type_response === TypeResponseFormField.DATE" >
                <div class="form-group col-lg-11 col-md-11 col-sm-11 datetime-form">
                    <Datetime type="date"
                              placeholder="Formato GG/MM/AAAA"
                              :value-zone="TIMEZONE_DEFAULT"
                              :zone="TIMEZONE_DEFAULT"
                              v-model="formValue.date_response"
                              :phrases="{ok: 'Select', cancel: 'Annulla'}"
                              input-class="form-control"></Datetime>
                </div>

				<div class="form-group col-lg-1 col-md-1 col-sm-1 datetime-delete-container">
					<button @click="formValue.date_response = null" class="btn btn-default datetime-delete-icon">
						<i class="las la-trash"></i>
					</button>
				</div>

            </div>

            <div v-else-if="formField.type_response === TypeResponseFormField.DATETIME" >

                <div class="form-group col-lg-11 col-md-11 col-sm-11 datetime-form">
                    <Datetime type="datetime"
                              :value-zone="TIMEZONE_DEFAULT"
                              :zone="TIMEZONE_DEFAULT"
                              placeholder="Formato GG/MM/AAAA HH:MM"
                              v-model="formValue.datetime_response"
                              :phrases="{ok: 'Select', cancel: 'Annulla'}"
                              input-class="form-control"></Datetime>
                </div>

				<div class="form-group col-lg-1 col-md-1 col-sm-1 datetime-delete-container">
					<button @click="formValue.datetime_response = null" class="btn btn-default datetime-delete-icon">
						<i class="las la-trash"></i>
					</button>
				</div>

            </div>




        <!-- Delete here boolean, maybe reintegrate parent choice, check if parent is field -->
            <div v-else-if="(formField.type_response === TypeResponseFormField.BOOLEAN
                            || formField.type_response === TypeResponseFormField.MULTIPLE_CHOICE_BOOLEAN
                            || formField.type_response === TypeResponseFormField.SINGLE_CHOICE_BOOLEAN)">
                <select class="form-control" v-model="formValue.boolean_response">
                    <option :value="null" v-if="formField.choice_nullable">NA</option>
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                </select>

                <select class="form-control"  v-model="singleChoiceSelected" v-if="formValue.boolean_response && formField.type_response === TypeResponseFormField.SINGLE_CHOICE_BOOLEAN" style="margin-left: 10px; margin-top: 5px;">
                    <option v-for="formFieldChild in formField.children" :key="formFieldChild.id"
                            :value="formFieldChild">{{formFieldChild.name}}</option>
                </select>
                <form-field  style="margin-left: 25px; margin-top: 5px;" v-if="formField.type_response === TypeResponseFormField.SINGLE_CHOICE_BOOLEAN && singleChoiceSelected && formValue.boolean_response" :values-default="valuesDefault" v-bind:key="singleChoiceSelected.id" :form-field="singleChoiceSelected"></form-field>

            </div>



            <div v-else-if="formField.type_response === TypeResponseFormField.SINGLE_CHOICE" style="margin-left: 10px;">
                <select class="form-control"  v-model="singleChoiceSelected">
                    <option :value="null" v-if="formField.choice_nullable">NA</option>
                    <option v-for="formFieldChild in formField.children" :key="formFieldChild.id"
                            :value="formFieldChild">{{formFieldChild.name}}</option>
                </select>

                <form-field v-if="singleChoiceSelected" :values-default="valuesDefault" v-bind:key="singleChoiceSelected.id" :form-field="singleChoiceSelected"></form-field>

            </div>


        </div>

        <div v-if="formField.type_response !== TypeResponseFormField.SINGLE_CHOICE
                    && formField.type_response !== TypeResponseFormField.MULTIPLE_CHOICE
                    && formField.type_response !== TypeResponseFormField.SINGLE_CHOICE_BOOLEAN
                    && formField.type_response !== TypeResponseFormField.MULTIPLE_CHOICE_BOOLEAN
                    && ((formField.type_response !== TypeResponseFormField.BOOLEAN) || (formField.type_response === TypeResponseFormField.BOOLEAN && formValue.boolean_response))" style="margin-left: 10px">
            <form-field :values-default="valuesDefault" v-for="formFieldChild in formField.children" v-bind:key="formFieldChild.id" :form-field="formFieldChild"></form-field>
        </div>

    </div>
</template>

<script>
    import {TIMEZONE_DEFAULT, TYPE_FORM_FIELD, TYPE_RESPONSE_FORM_FIELD} from "../../common/constants";
    import EventBus from '../../common/eventBus'
    import {Datetime} from "vue-datetime";
    import 'vue-datetime/dist/vue-datetime.css'

    export default {
        name: "FormField",
        components: {Datetime},
        props: {
            formField: {
                type: Object,
                require: true
            },
            valuesDefault: {
                type: Object,
                required: false
            }
        },
        data: () => {
            return {
                TIMEZONE_DEFAULT: TIMEZONE_DEFAULT,
                TypeFormField: TYPE_FORM_FIELD,
                TypeResponseFormField: TYPE_RESPONSE_FORM_FIELD,
                singleChoiceSelected: null,
                formValue: {
                    boolean_response: null,
                    numeric_response: null,
                    float_response: null,
                    string_response: null,
                    duration_response: null,
                    date_response: null,
                    datetime_response: null,
                    single_choice_selected: null
                },
                error: {
                    numeric: false
                }
            }
        },
        mounted(){
            this.populateField(this.valuesDefault);

            if (!this.formField.choice_nullable) {
                if (this.formField.type_response === this.TypeResponseFormField.SINGLE_CHOICE) {
                    this.singleChoiceSelected = this.formField.children[0];
                } else if (this.formField.type_response === this.TypeResponseFormField.SINGLE_CHOICE_BOOLEAN ||
                    this.formField.type_response === this.TypeResponseFormField.BOOLEAN) {
                    if (this.formValue.boolean_response == null && !this.formField.choice_nullable){
                        this.formValue.boolean_response = false;
                    }
                    this.singleChoiceSelected = this.formField.children[0];
                }
            }

        },
        methods: {
            populateField(valuesDefault) {
                if (valuesDefault && valuesDefault.values) {
                    for (let i = 0; i < valuesDefault.values.length; i++) {
                        if (valuesDefault.values[i].form_field === this.formField.id) {
                            if (valuesDefault.values[i].single_choice_selected) {
                                this.singleChoiceSelected = valuesDefault.values[i].single_choice_selected;
                            }
                            this.formValue = valuesDefault.values[i];
                            break;
                        }
                    }
                }
            }
        },
        watch: {
            formValue: {
                handler(newVal) {
                    newVal.form_field = this.formField.id;


                    EventBus.$emit('entry_construction', newVal);

                    this.error.numeric = false;

                    if (newVal.numeric_response != null) {

                        if (this.formField.max_numeric_value != null) {
                            if (newVal.numeric_response > this.formField.max_numeric_value) {
                                this.error.numeric = true;
                            }
                        }

                        if (this.formField.min_numeric_value != null) {
                            if (newVal.numeric_response < this.formField.min_numeric_value) {
                                this.error.numeric = true;
                            }
                        }

                    }

                },
                deep: true,
            },
            valuesDefault: function(newVal) {
                this.populateField(newVal);
            },
            singleChoiceSelected: function (newVal, oldValue) {
                if (oldValue) {
                    EventBus.$emit('entry_delete', oldValue);
                }

                if (newVal) {
                    this.formValue.single_choice_selected = newVal.id;
                } else {
                    EventBus.$emit('entry_delete', this.formField);
                }
            }
        }
    };
</script>

<style scoped>
    label {
        margin: 10px 0;
    }
</style>
