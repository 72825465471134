<template>
    <table class="table table-hover">
        <thead>
        <tr>
            <th>Codice Paziente</th>
            <th>Data di inserimento</th>
            <th>Note</th>
            <th></th>
        </tr>
        </thead>
        <tbody id="entry-list">
        <EntryRow
                :entry="entry"
                v-for="entry in entries"
                v-bind:key="entry.id"
                @open-entry="$emit('open-entry', entry.id)"
                @open-delete="$emit('open-delete', entry.id)"
                :delete-permission="deletePermission"
        ></EntryRow>
        </tbody>
    </table>
</template>

<script>
    import EntryRow from "./EntryRow";
    export default {
        name: "EntryTable",
        components: { EntryRow },
        props: {
            entries: {
                type: Array,
                required: true
            },
            deletePermission: Boolean
        }
    };


</script>
