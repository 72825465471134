<template>
    <tr class="clickable-row">
        <td><a href="" @click.prevent.stop>{{entry.patient.code_external}}</a></td>
        <td><a href="" @click.prevent.stop>{{getLongDateFromISO(entry.created_date)}}</a></td>
        <td><a href="" @click.prevent.stop>{{entry.note}}</a></td>
        <td class="text-right">
            <a class="btn btn-default" @click.prevent.stop="$emit('open-entry', entry.id)">
                <i class="las la-eye"></i>Mostra record
            </a>
            <a class="btn btn-danger"  v-show="deletePermission" @click.prevent.stop="$emit('open-delete', entry.id)">
                <i class="la la-trash"></i>
            </a>
        </td>
    </tr>
</template>

<script>
    import {Utils} from "../../common/utils";

    export default {
        name: "EntryRow",
        props: {
            entry: {
                type: Object,
                require: true
            },
            deletePermission: Boolean
        },
        methods: {
            getLongDateFromISO(date){
                return Utils.getLongDateFromISO(date)
            }
        }
    };
</script>
