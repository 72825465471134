<template>
    <ModalContainer :visible="visible" :loading="loading">
        <template v-slot:header>
            <button type="button" class="close" @click="$emit('close-entry-modal')"><i class="las la-times"></i></button>
            <h4 class="modal-title">Nuovo record</h4>
        </template>
        <template v-slot:body>
            <EntryForm :entry-prop="entry"
                       @change="onChangeEntry"
                       @change-patient="onChangePatient"
                       @change-compilation-date="onChangeCompilationDate"
                       @change-compiling-doctor="onChangeCompilingDoctor"
                       @change-note="onChangeNote"></EntryForm>
        </template>

        <template v-slot:footer>
            <div
                v-show="errorMessage"
                class="alert alert-danger"
                role="alert"
                style="text-align: start"
            >
                {{ errorMessage}}

            </div>
            <a class="btn btn-primary btn-lg btn-block"
               href=""
               @click.prevent.stop="createNewEntry"
            >
                <i class="las la-check"></i>
                Aggiungi
            </a>
            <a class="btn btn-default  btn-lg btn-block"
               href=""
               @click.prevent.stop="$emit('close-entry-modal')"
            >
                Chiudi
            </a>
        </template>
    </ModalContainer>
</template>

<script>
    import EntryForm from "../entries/EntryForm";
    import ModalContainer from "./ModalContainer";
    import {createEntry} from "../../api";
    export default {
        name: "AddEntryModal",
        props: {
            visible: {
                type: Boolean,
                required: true
            }
        },
        data: () => {
            return {
                loading: false,
                entry: {
                },
                note: "",
				compiling_doctor: "",
				compilation_date: null,
                patient: null,
                errorMessage: ""
            }
        },
        components: {EntryForm, ModalContainer},
        methods: {
            onChangeEntry(entry) {
                this.entry = entry;
            },
            onChangeNote(note) {
                this.note = note;
            },
			onChangeCompilingDoctor(compilingDoctor) {
				this.compiling_doctor = compilingDoctor;
			},
			onChangeCompilationDate(compilationDate) {
				this.compilation_date = compilationDate;
			},
            onChangePatient(patient) {
                this.patient = patient;
            },
            async createNewEntry() {
                this.errorMessage = ""

                // Checking constraints
                // let holterECG = this.entry[76] || {}
                // let sef = this.entry[91] || {}

                // if (!(holterECG.boolean_response === true || sef.boolean_response === true)) {
                //     this.errorMessage = "Per poter aggiungere un record deve essere presente almeno un SEF (Studio Elettro Fisiologico) o un Holter ECG"
                //     return
                // }

                if (this.patient.hospital === undefined || this.patient.hospital === null ) {
                  this.errorMessage = "Per poter aggiungere un record è necessario selezionare un ospedale nella sezione \"Nuovo Paziente\""
                  return
                }

                if (this.patient.birthday === undefined || this.patient.birthday === null ||  this.patient.birthday === "") {
                  this.errorMessage = "Per poter aggiungere un record è necessario selezionare una data di nascita nella sezione \"Nuovo Paziente\""
                  return
                }

                if (this.patient.code_external === undefined || this.patient.code_external === null ||  this.patient.code_external === "") {
                  this.errorMessage = "Per poter aggiungere un record è necessario un codice paziente univoco nella sezione \"Nuovo Paziente\""
                  return
                }

                this.loading = true;
                try {
                    const resultEntry = await createEntry(
                        this.$route.params.research_project_id,
                        this.$route.params.category_id, this.patient,
                        this.note,
                        this.entry,
                        this.compiling_doctor,
                        this.compilation_date);
                    this.loading = false;
                    this.$emit('success-entry-add');
                    this.$route.params.successMessage = 'Nuovo record creato con successo';

                    console.log(resultEntry)

                } catch (e) {
                    this.loading = false;
                    console.log(e)
                    this.errorMessage = "Assicurati di aver inserito un codice paziente univoco nella sezione \"Nuovo Paziente\""
                    return
                }
            }
        }
    };
</script>
