<template>
    <ModalContainer :visible="visible" :loading="loading">
        <template v-slot:header>
            <button type="button" class="close" @click="$emit('close-entry-modal')"><i class="las la-times"></i></button>
            <h4 v-if="updatePermission" class="modal-title">Aggiorna record</h4>
            <h4 v-else class="modal-title">Consulta il record</h4>
        </template>
        <template v-slot:body>
            <EntryForm :entry-prop="entry" :values-default="entryProp"
                       @change="onChangeEntry"
                       @change-patient="onChangePatient"
                       @change-note="onChangeNote"
                       @change-compilation-date="onChangeCompilationDate"
                       @change-compiling-doctor="onChangeCompilingDoctor"
			></EntryForm>
        </template>

        <template v-slot:footer >
            <a class="btn btn-primary btn-lg btn-block"
               v-if="updatePermission"
               href=""
               @click.prevent.stop="updateEntry()"
            >
                <i class="las la-check"></i>
                Aggiorna
            </a>
            <a class="btn btn-primary  btn-lg btn-block"
               v-else
               href=""
               @click.prevent.stop="$emit('close-entry-modal')"
            >
                Chiudi
            </a>
        </template>

    </ModalContainer>
</template>

<script>
    import EntryForm from "../entries/EntryForm";
    import ModalContainer from "./ModalContainer";
    import {updateEntry} from "../../api";
    export default {
        name: "EditEntryModal",
        props: {
            visible: {
                type: Boolean,
                required: true
            },
            entryProp: {
                type: Object,
                default: null
            },
            updatePermission: Boolean
        },
        data: () => {
            return {
                loading: false,
                errorMessage: "",
                entry: {},
                note: "",
				compiling_doctor: "",
				compilation_date: null,
                patient: null
            }
        },
        components: {EntryForm, ModalContainer},
        methods: {
            async updateEntry() {
                this.loading = true;
                try {
                    const resultEntry = await updateEntry(this.$route.params.research_project_id,
						this.entryProp.id,
						this.patient,
						this.note,
						this.entry,
						this.compiling_doctor,
						this.compilation_date);
                    this.loading = false;
                    this.$emit('success-entry-edit');
                    this.$route.params.successMessage = 'Nuovo record aggiornato con successo';
                    console.log(resultEntry)

                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
			onChangeCompilingDoctor(compilingDoctor) {
				this.compiling_doctor = compilingDoctor;
			},
			onChangeCompilationDate(compilationDate) {
				this.compilation_date = compilationDate;
			},
            onChangeNote(note) {
                this.note = note;
            },
            onChangePatient(patient) {
                this.patient = patient;
            },
            onChangeEntry(entry) {
                this.entry = entry;
            },
        }
    };
</script>
