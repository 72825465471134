<template>
    <ContainerWithMenu :loading="loading">
        <div class="page-content">
            <div class="row margin-bottom-20">
                <div class="col-xs-12 col-md-3">
                    <h1 v-if="parseInt($route.params.category_id) === CategoryEntry.MEDICAL_HISTORY" class="h2 margin-0">
                        <i class="las la-file-medical-alt margin-bottom-5" ></i>
                        Medical History
                    </h1>
                    <h1 v-else-if="parseInt($route.params.category_id) === CategoryEntry.ABLATION_PROCEDURE" class="h2 margin-0">
                        <i class="las la-procedures margin-bottom-5" ></i>
                        Ablation Procedure
                    </h1>
                    <h1 v-else-if="parseInt($route.params.category_id) === CategoryEntry.FOLLOW_UP" class="h2 margin-0">
                        <i class="las la-notes-medical margin-bottom-5" ></i>
                        Follow-up
                    </h1>
                </div>
                <div class="col-xs-12 col-md-3 text-right">
                  <ModelListSelect class="form-control" id="hospital-options" name="hospital-options" :list="hospitals" option-value="id" option-text="name" v-model="selectedHospital">Ospedale</ModelListSelect>
                </div>
                <div class="col-xs-12 col-md-6 text-right">
                    <button
                            style="margin-right: 10px"
                            class="btn btn-primary btn-action-mobile"
                            @click="showAddRecordModal"
                            v-show="createPermission"
                    >
                        <i class="las la-plus"></i>
                        <span class="button-text">Aggiungi Record</span>
                    </button>
                    <button
                            class="btn btn-default"
                            @click.prevent.stop="$router.push({name:'research_project_detail',
                                         params: { research_project_id: $route.params.research_project_id}})"
                    >
                        <i class="las la-undo"></i>
                        <span class="button-text">Indietro</span>
                    </button>
                </div>
            </div>
            <div class="table-responsive" v-if="entries.length > 0">
                <EntryTable :entries="entries" @open-entry="openEntry" @open-delete="openDelete" :delete-permission="deletePermission"></EntryTable>
            </div>
            <div v-else>
                <span class="text-muted">Non sono stati fatti inserimenti per questo progetto di ricerca</span>
            </div>
            <div class="text-center">
                <Pagination
                    v-if="enabledPagination"
                    v-show="numberPagesEntry > 1"
                    :page-count="numberPagesEntry"
                    :click-handler="paginateCallback" />
            </div>

            <AddEntryModal
                    v-if="!resetAddEntryModal"
                    v-show="addEntryModalVisible"
                    :visible="addEntryModalVisible"
                    @close-entry-modal="addEntryModalVisible=false"
                    @success-entry-add="successEntryAddCallback"></AddEntryModal>

            <EditEntryModal
                v-show="editEntryModalVisible"
                :visible="editEntryModalVisible"
                @close-entry-modal="editEntryModalVisible=false"
                :entry-prop="selectedEntry"
                :update-permission="updatePermission"
                @success-entry-edit="successEntryEditCallback"></EditEntryModal>

            <DeleteEntryModal
                    v-show="deleteEntryModalVisible"
                    :visible="deleteEntryModalVisible"
                    :entry-prop="selectedEntry"
                    @close-entry-modal="deleteEntryModalVisible=false"
                    @success-entry-delete="successEntryDeleteCallback"></DeleteEntryModal>
        </div>
    </ContainerWithMenu>
</template>

<script>
    import ContainerWithMenu from "../components/container/ContainerWithMenu";
    import {Utils} from "../common/utils";
    import EntryTable from "../components/entries/EntryTable";
    import Pagination from "../components/common/Pagination";
    import {CATEGORY_ENTRY, LIMIT_RECORDS_API} from "../common/constants";
    import {getEntries, getEntry} from "../api";
    import AddEntryModal from "../components/modal/AddEntryModal";
    import EditEntryModal from "../components/modal/EditEntryModal";
    import {StorageHelper} from "../common/storageHelper";
    import DeleteEntryModal from "../components/modal/DeleteEntryModal";
    import {getAllHospital} from "@/api";
    import {ModelListSelect} from "vue-search-select";


    export default {
        name: "Entries",
        components: {DeleteEntryModal, EntryTable, ContainerWithMenu, Pagination, AddEntryModal, EditEntryModal, ModelListSelect},
        data: function() {
            return {
                entries: [],
                hospitals: [],
                allHospitalOption: {
                  id: 0,
                  name: "Tutti gli ospedali"
                },
                selectedHospital: null,
                countEntry: 0,
                limitEntry: LIMIT_RECORDS_API,
                currentPageEntry: 1,
                loading: false,
                newEntryModalVisible: false,
                CategoryEntry: CATEGORY_ENTRY,
                addEntryModalVisible: false,
                editEntryModalVisible: false,
                deleteEntryModalVisible: false,
                selectedEntry: {},
                resetAddEntryModal: false,
                createPermission: false,
                updatePermission: false,
                deletePermission: false,
                enabledPagination: false
            };
        },
        mounted() {
            this.selectedHospital = this.allHospitalOption
            this.getEntries()
            this.getHospitals()

            StorageHelper.getUser().permissions.forEach(permission => {
                if (permission.research_project == this.$route.params.research_project_id) {
                    this.createPermission = permission.create
                    this.updatePermission = permission.update
                    this.deletePermission = permission.delete
                }
            });
        },
        computed: {
            numberPagesEntry: function () {
                return Utils.getNumberPageByCountLimit(this.countEntry, this.limitEntry);
            }
        },
        methods: {
            async openEntry(id) {
                this.loading = true;
                try {
                    this.selectedEntry = await getEntry(this.$route.params.research_project_id,
                        id);
                    this.loading = false;
                    this.showEditRecordModal()
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
            async openDelete(id){
                this.loading = true;
                try {
                    this.selectedEntry = await getEntry(this.$route.params.research_project_id,
                        id);
                    this.loading = false;
                    this.showDeleteRecordModal()
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
            successEntryAddCallback() {
                this.addEntryModalVisible=false;
                this.$nextTick(() => {
                    this.resetAddEntryModal=true;

                    this.$nextTick(() => {
                            this.resetAddEntryModal = false;
                        });
                });
                this.getEntries()

            },

            successEntryEditCallback() {
                this.editEntryModalVisible=false;
                this.getEntries()
            },
            successEntryDeleteCallback() {
                this.deleteEntryModalVisible=false;
                this.getEntries()
            },
            showAddRecordModal() {
                this.addEntryModalVisible = true
            },
            showEditRecordModal() {
                this.editEntryModalVisible = true
            },
            showDeleteRecordModal() {
                this.deleteEntryModalVisible = true

            },
            paginateCallback(pageNum) {
                this.getEntries(pageNum, this.selectedHospital.id)
            },
            async getEntries(page = 1, selectedHospital = 0) {
                this.loading = true;
                try {
                    let entriesResponse = await getEntries(this.$route.params.research_project_id,
                        this.$route.params.category_id ,
                        page, selectedHospital);
                    this.countEntry = entriesResponse.count;
                    this.entries = entriesResponse.results;

                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
            async getHospitals() {
              this.loading = true;
              try {
                this.hospitals = await getAllHospital()
                this.hospitals.unshift(this.allHospitalOption)
                console.log(this.hospitals)
                this.loading = false
              } catch (e) {
                this.loading = false;
                console.log(e)
              }
            }
        },
        watch: {
          selectedHospital: function(value) {
            console.log(value)
            this.getEntries(1, value.id)
            this.enabledPagination = false;
            this.$nextTick(() => {
              this.enabledPagination = true;
            });
          }
        }
    };
</script>
