<template>
    <div v-show="visible">
        <transition name="modal">
            <div class="modal in">
                <div class="modal-dialog" v-bind:class="{ 'modal-lg': isLarge }" role="document">
                    <div class="modal-content">
                        <div class="modal-header" v-if="showHeader">
                            <slot name="header"></slot>
                        </div>

                        <div class="modal-body">
                            <slot name="body"></slot>
                        </div>
                        <div class="modal-footer" v-if="showFooter">
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <SpinnerLoader v-show="loading"></SpinnerLoader>
    </div>
</template>

<script>
    import SpinnerLoader from "../common/SpinnerLoader";

    export default {
        name: "ModalContainer",
        components: {SpinnerLoader},
        props: {
            visible: {
                type: Boolean,
                require: true
            },
            loading: {
                type: Boolean,
                default: false
            },
            showFooter: {
                type: Boolean,
                default: true
            },
            showHeader: {
                type: Boolean,
                default: true
            },
            isLarge: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            visible(newVal) {
                if (newVal) {
                    document.body.classList.add('modal-open')
                } else {
                    document.body.classList.remove('modal-open')
                }
            }
        }
    }
</script>

<style scoped>

</style>