<template>
    <div>

        <VmCollapse>
            <VmCollapseItem title="Nuovo paziente" v-if="categoryId === 1">
                <div  @click.prevent.stop="">
                    <div class="form-group">
                        <label class="control-label">Ospedale</label>
                        <ModelListSelect class="form-control" id="hospital-options" name="hospital-options" :list="hospitalAvailable" option-value="id" option-text="composed_name" v-model="patient.hospital"></ModelListSelect>
                    </div>

                    <div class="form-group">
                        <label class="control-label">Codice Paziente</label>
                        <input class="form-control" type="text" v-model="patient.code_external">
                    </div>

					<div class="form-group col-lg-6 col-md-6 col-sm-6" style="padding-left: 0">
						<label class="control-label">Iniziale nome</label>
						<input class="form-control" type="text" placeholder="Esempio: SA" maxlength="2" v-model.number="patient.initial_first_name">
					</div>
					<div class="form-group col-lg-6 col-md-6 col-sm-6" style="padding-right: 0">
						<label class="control-label">Iniziale Cognome</label>
						<input class="form-control" type="text" placeholder="Esempio: DE" maxlength="2" v-model.number="patient.initial_last_name">
					</div>
                    <label class="control-label">Data di nascita</label>
                    <div class="form-group col-lg-11 col-md-11 col-sm-11 datetime-form">
                        <Datetime type="date"
                                  :value-zone="TIMEZONE_DEFAULT"
                                  :zone="TIMEZONE_DEFAULT"
                                  v-model="patient.birthday"
                                  placeholder="Formato GG/MM/AAAA"
                                  :phrases="{ok: 'Select', cancel: 'Annulla'}"
                                  input-class="form-control "></Datetime>

                    </div>

					<div class="form-group col-lg-1 col-md-1 col-sm-1 datetime-delete-container">
						<button @click="patient.birthday = null"  class="btn btn-default datetime-delete-icon">
							<i class="las la-trash"></i>
						</button>
					</div>

                    <div class="form-group col-lg-12 col-md-12 col-sm-12" style="padding: 0" :class="{'has-error': errorPatient.email}">
                        <label class="control-label">Email</label>
                        <input class="form-control" placeholder="Esempio: mario.rossi@gmail.com" type="email" v-model="patient.contact_email">
                        <span class="help-block" v-if="errorPatient.email">Email non valida</span>
                    </div>

                    <div class="form-group" :class="{'has-error': errorPatient.phone}">
                        <label class="control-label">Telefono</label>
                        <input class="form-control" placeholder="Esempio: +39 3401231231" type="text" v-model="patient.contact_tel">
                        <span class="help-block" v-if="errorPatient.phone">Numero di telefono non valido</span>
                    </div>

                    <div class="form-group">
                        <label class="control-label">Sesso</label>
                        <select class="form-control"  v-model="patient.sex">
                            <option value="M">Maschio</option>
                            <option value="F">Femmina</option>
                        </select>
                    </div>


                    <div class="form-group" :class="{'has-error': errorPatient.height}">
                        <label class="control-label">Altezza</label>
                        <div class="input-group" >
                            <input placeholder="Esempio: 178" type="number" v-model.number="patient.height" class="form-control">
                            <span class="input-group-addon">cm</span>
                        </div>
                        <span class="help-block" v-if="errorPatient.height">Altezza non valida: Inserire un valore numerico positivo</span>

                    </div>


                    <div class="form-group" :class="{'has-error': errorPatient.weight}">
                        <label class="control-label">Peso in KG</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="Esempio: 84" type="number" step="0.01" v-model.number="patient.weight">
                            <span class="input-group-addon">KG</span>
                        </div>
                        <span class="help-block" v-if="errorPatient.weight">Peso non valido</span>
                    </div>

                    <label class="control-label">BMI</label>
                    <div class="input-group form-group">
                        <input class="form-control" type="number" placeholder="Esempio: 23,87" step="0.01" v-model.number="patient.bmi">
                        <span class="input-group-addon">(kg/m2)</span>
                    </div>

                </div>

            </VmCollapseItem>
            <VmCollapseItem title="Inizio" v-if="categoryId !== 1">
                <div  @click.prevent.stop="">
                    <div class="form-group" style="padding-top: 5px; padding-bottom: 100px">
                        <label class="control-label">Paziente</label>
                        <ModelListSelect class="form-control" id="patient-options" name="patient-options" :list="patientAvailable" option-value="id" :custom-text="patientText" v-model="patient"></ModelListSelect>
                    </div>
                </div>
            </VmCollapseItem>

            <VmCollapseItem v-for="formField in formFields" v-bind:key="formField.id"  :title="formField.name">
                <div  @click.prevent.stop="">
                    <FormField :form-field="formField" :values-default="valuesDefault"></FormField>
                </div>
            </VmCollapseItem>

            <VmCollapseItem title="Completamento">
                <div  @click.prevent.stop="">
                    <div class="form-group">
                        <label class="control-label">Nota</label>
                        <textarea class="form-control" type="text" v-model="note"/>
                    </div>
					<div class="form-group">
						<label class="control-label">Medico Compilatore</label>
						<input class="form-control" type="text" v-model="compiling_doctor"/>
					</div>
					<div>
						<label class="control-label">Data Compilazione</label>

                        <div class="form-group col-lg-11 col-md-11 col-sm-11 datetime-form">
                            <Datetime type="date"
                                      placeholder="Formato GG/MM/AAAA"
                                      :value-zone="TIMEZONE_DEFAULT"
                                      :zone="TIMEZONE_DEFAULT"
                                      v-model="compilation_date"
                                      :phrases="{ok: 'Select', cancel: 'Annulla'}"
                                      input-class="form-control"></Datetime>
                        </div>

						<div class="form-group col-lg-1 col-md-1 col-sm-1 datetime-delete-container">
							<button @click="compilation_date = null" class="btn btn-default datetime-delete-icon">
								<i class="las la-trash"></i>
							</button>
						</div>
					</div>
                </div>
            </VmCollapseItem>
        </VmCollapse>

    </div>
</template>

<script>
    import {getAllHospital, getAllPatients, getFormFields} from "../../api";
    import FormField from "../form_field/FormField";
    import EventBus from '../../common/eventBus';
    import {VmCollapse, VmCollapseItem} from 'vue-multiple-collapse'
    import { ModelListSelect } from 'vue-search-select'
    import {TIMEZONE_DEFAULT} from "../../common/constants";
    import {Datetime} from "vue-datetime";
    import 'vue-datetime/dist/vue-datetime.css'
    import {Utils} from "../../common/utils";

    export default {
        name: "EntryForm",
        components: {FormField, VmCollapse, VmCollapseItem, ModelListSelect, Datetime},
        props: {
            TIMEZONE_DEFAULT: TIMEZONE_DEFAULT,
            entryProp: {
                type: Object,
                required: true
            },
            valuesDefault: {
                type: Object,
                required: false
            }
        },
        data: () => {
            return {
                patientAvailable: [],
                hospitalAvailable: [],
                note: "",
				compiling_doctor: "",
				compilation_date: null,
                patient: {
                    sex: "M"
                },
                errorPatient: {
                    email: false,
                    phone: false,
                    height: true,
                    weight: false
                },
                entry: {
                },
                formFields: []
            }
        },
        mounted: async function () {
            let self = this;
            this.entry = this.entryProp;

            this.loading = true;
            try {
                this.formFields = await getFormFields(this.$route.params.research_project_id,
                    this.$route.params.category_id ,);
                this.loading = false;
            } catch (e) {
                this.loading = false;
                console.log(e)
            }

            this.patientAvailable = await getAllPatients(this.$route.params.research_project_id);
            this.hospitalAvailable = await getAllHospital();


            EventBus.$on('entry_construction', function (formValue) {
                if (formValue.form_field) {
                    self.entry[formValue.form_field] = formValue
                }
            });

            EventBus.$on('entry_delete', function (formField) {
                self.deleteEntryForm(formField)

            });
        },
        computed: {
          categoryId: function() {
              return parseInt(this.$route.params.category_id);
          }
        },
        methods: {
			patientText(item) {
				return `${item.code_external} - ${Utils.getStringOrNA(item.initial_first_name)}.${Utils.getStringOrNA(item.initial_last_name)}.`
			},
            deleteEntryForm(formField) {
                if (this.entry[formField.id]) {
                    delete this.entry[formField.id];
                    for (let j = 0; j < formField.children.length; j++) {
                        this.deleteEntryForm(formField.children[j])
                    }
                }
            }
        },
        watch: {
            valuesDefault: function(newVal) {
               if (newVal) {
                   this.note = newVal.note;
                   this.compilation_date = newVal.compilation_date;
                   this.compiling_doctor = newVal.compiling_doctor;
                   this.patient = newVal.patient
               }
             },

            entryProp: function(newVal) {
                this.entry = newVal;
            },
            note: function(newVal) {
                this.$emit('change-note', newVal)
            },
			compiling_doctor: function(newVal) {
				this.$emit('change-compiling-doctor', newVal)
			},
			compilation_date: function(newVal) {
				this.$emit('change-compilation-date', newVal)
			},
            patient:  {
                handler(newVal) {
                    this.$emit('change-patient', newVal);

                    console.log(newVal);

                    this.errorPatient.email = false;
                    this.errorPatient.phone = false;
                    this.errorPatient.height = false;
                    this.errorPatient.weight = false;


                    if (newVal.contact_email != null) {
                        this.errorPatient.email = !Utils.validateEmail(newVal.contact_email);
                    }

                    if (newVal.contact_tel != null) {
                        this.errorPatient.phone = !Utils.validatePhone(newVal.contact_tel);
                    }

                    if (newVal.height != null) {
                        this.errorPatient.height = !Utils.validateHeightPatient(newVal.height);
                    }

                    if (newVal.weight != null) {
                        this.errorPatient.weight = !Utils.validateWeightPatient(newVal.weight);
                    }

                },
                deep: true,
            },
            entry: {
                handler(newVal) {
                    this.$emit('change', newVal)
                },
                deep: true,
            }
        }
    }
</script>

<style>
    .vm-collapse-item__content > div {
        padding: 10px;
    }

     .vm-collapse-item__content {
         padding: 0!important;
     }

</style>
