<template>
    <ModalContainer :visible="visible" :loading="loading">
        <template v-slot:header>
            <button type="button" class="close" @click="$emit('close-entry-modal')"><i class="las la-times"></i></button>
            <h4 class="modal-title">Elimina record</h4>
        </template>
        <template v-slot:body>
            <h8>Sei sicuro di voler eliminare questo record?</h8>
        </template>

        <template v-slot:footer>
            <a class="btn btn-danger btn-lg btn-block"
               href=""
               @click.prevent.stop="deleteEntry()"
            >
                <i class="las la-check"></i>
                Elimina
             </a>
            <a class="btn btn-default  btn-lg btn-block"
               href=""
               @click.prevent.stop="$emit('close-entry-modal')"
            >
                Annulla
            </a>
        </template>

    </ModalContainer>
</template>

<script>
    import ModalContainer from "./ModalContainer";
    import {deleteEntry} from "../../api";

    export default {
        name: "DeleteEntryModal",
        props: {
            visible: {
                type: Boolean,
                required: true
            },
            entryProp: {
                type: Object,
                default: null
            },
        },
        data: () => {
            return {
                loading: false,
                errorMessage: "",
                entry: {},
                note: "",
                patient: null
            }
        },
        components: {ModalContainer},
        methods: {
            async deleteEntry() {

                this.loading = true;
                try {
                    await deleteEntry(this.$route.params.research_project_id,
                        this.entryProp.id);
                    this.loading = false;
                    this.$emit('success-entry-delete');
                    this.$route.params.successMessage = 'Record eliminato con successo';
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }

            }
        }
    };
</script>
